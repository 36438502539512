import revive_payload_client_4sVQNw7RlN from "/home/runner/work/goscope.net/goscope.net/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/goscope.net/goscope.net/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/goscope.net/goscope.net/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/goscope.net/goscope.net/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/goscope.net/goscope.net/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/goscope.net/goscope.net/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/goscope.net/goscope.net/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/goscope.net/goscope.net/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/goscope.net/goscope.net/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_YksOOA8Nkt from "/home/runner/work/goscope.net/goscope.net/node_modules/nuxt-snackbar/dist/runtime/plugin.js";
import plugin_client_UYiXMU8ZyN from "/home/runner/work/goscope.net/goscope.net/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/runner/work/goscope.net/goscope.net/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/home/runner/work/goscope.net/goscope.net/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_YksOOA8Nkt,
  plugin_client_UYiXMU8ZyN,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i
]